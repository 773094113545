<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-button variant="primary" class="d-flex align-items-center" @click="syncGames"
      :disabled="isSyncing || gamesToSync == 0">
      <template v-if="isSyncing">
        <feather-icon icon="RefreshCcwIcon" size="18" class="cursor-pointer rotate" />
      </template>
      <template v-else>
        {{ $t("buttons.synchronize") }}
        <feather-icon icon="RefreshCcwIcon" size="18" class="cursor-pointer ml-1" />
      </template>
    </b-button>
    <b-row>
      <b-col cols="12" md="6" class="mt-3">
        <b-card class="h-100" :title="$t('labels.synchronized')">
          <b-card-text class="d-flex align-items-center">
            <feather-icon icon="CheckCircleIcon" size="24" class="mr-2 text-success" />
            <p class="mb-0">
              <strong>{{ synchronizedGames }}</strong>
              {{ $t("labels.synchronized-games") }}
            </p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mt-3">
        <b-card class="h-100" :title="$t('labels.to-synchronize')">
          <b-card-text class="d-flex align-items-center">
            <feather-icon icon="AlertCircleIcon" size="24" class="mr-2 text-warning" />
            <p class="mb-0">
              <strong>{{ gamesToSync }}</strong>
              {{ $t("labels.games-to-sync") }}
            </p>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- sync manual -->
      <b-col cols="12" class="mt-3">
        <SyncGameManual />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BCard, BCol, BButton, BCardText, BRow, BOverlay } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SyncGameManual from "./syncGameManual.vue";
export default {
  components: {
    BCard,
    BButton,
    BCardText,
    BCol,
    BRow,
    BOverlay,
    ToastificationContent,
    SyncGameManual,
  },
  data() {
    return {
      synchronizedGames: null,
      gamesToSync: null,
      isSyncing: false,
      loadingData: false,
    };
  },
  methods: {
    async indexGetListGames() {
      try {
        this.loadingData = true;
        let res = await this.$store.dispatch("bonus/getListGames");
        if (res.data) {
          this.synchronizedGames = res.data.gamesWithPromofy;
          this.gamesToSync = res.data.gamesWithoutPromofy;
        }
        this.loadingData = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
    async syncGames() {
      this.isSyncing = true;

      try {
        let res = await this.$store.dispatch("bonus/syncGames");
        if (res.data.length > 0) {
          this.indexGetListGames();
          this.isSyncing = false;
        } else {
          this.isSyncing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "No hay juegos para sincronizar",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "No hay juegos para sincronizar",
            },
          });
        }

        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
        this.isSyncing = false;
        console.log(error);
      } finally {
        this.loadingData = false;
        this.isSyncing = false;
      }
    },
  },
  mounted() {
    this.indexGetListGames();
  },
};
</script>

<style scoped>
.mt-3 {
  margin-top: 1rem;
}

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
