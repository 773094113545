<template>
    <b-card class="h-100" :title="$t('labels.manual-synchronization')">
        <b-card-text>
            <p class="text-muted mb-3">
                <feather-icon icon="InfoIcon" size="16" class="mr-1" />
                {{ $t('labels.sync-explanation') }}
            </p>

            <div class="mb-3">
                <b-list-group>
                    <b-list-group-item class="d-flex align-items-center">
                        <feather-icon icon="ServerIcon" size="16" class="mr-2 text-primary" />
                        {{ $t('labels.sync-provider-only') }}
                    </b-list-group-item>
                    <b-list-group-item class="d-flex align-items-center">
                        <feather-icon icon="TagIcon" size="16" class="mr-2 text-primary" />
                        {{ $t('labels.sync-provider-brand') }}
                    </b-list-group-item>
                    <b-list-group-item class="d-flex align-items-center">
                        <feather-icon icon="LayersIcon" size="16" class="mr-2 text-primary" />
                        {{ $t('labels.sync-provider-brand-games') }}
                    </b-list-group-item>
                </b-list-group>
            </div>

            <b-alert v-if="syncModeMessage" show variant="info" class="mb-3">
                <div class="alert-body">
                    <feather-icon icon="RefreshCwIcon" size="16" class="mr-2" />
                    {{ syncModeMessage }}
                </div>
            </b-alert>

            <b-row>
                <b-col md="4">
                    <label>{{ $t("labels.supplier") }} <span class="text-danger">*</span></label>
                    <b-overlay :show="loadingProducts" rounded="lg" opacity="0.6">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary" />
                                <b-spinner type="grow" variant="dark" />
                                <b-spinner small type="grow" variant="secondary" />
                                <span class="sr-only">{{ $t("please-wait") }}</span>
                            </div>
                        </template>

                        <v-select class="mb-1" v-model="selectedProduct"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="productsOptions" label="name"
                            @input="productSelected" :reduce="(product) => product._id"
                            :placeholder="$t('labels.select-product')">
                        </v-select>
                    </b-overlay>
                </b-col>

                <b-col md="4">
                    <label>{{ $t("brands") }}</label>
                    <b-overlay :show="loadingBrands" rounded="lg" opacity="0.6">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary" />
                                <b-spinner type="grow" variant="dark" />
                                <b-spinner small type="grow" variant="secondary" />
                                <span class="sr-only">{{ $t("please-wait") }}</span>
                            </div>
                        </template>

                        <v-select class="mb-1" v-model="selectedBrand"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="brandsOptions" label="name"
                            :reduce="(brand) => brand._id" @input="brandSelected" :disabled="!selectedProduct"
                            :placeholder="$t('labels.select-brand')">
                        </v-select>
                    </b-overlay>
                </b-col>

                <b-col md="4">
                    <label>{{ $t("games") }}</label>
                    <b-overlay :show="loadingGames" rounded="lg" opacity="0.6">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary" />
                                <b-spinner type="grow" variant="dark" />
                                <b-spinner small type="grow" variant="secondary" />
                                <span class="sr-only">{{ $t("please-wait") }}</span>
                            </div>
                        </template>

                        <v-select class="mb-1" v-model="selectedGames"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="gamesOptions" label="gameName"
                            :reduce="(game) => game.gameId" multiple :disabled="!selectedBrand"
                            :placeholder="$t('labels.select-games')">
                        </v-select>
                    </b-overlay>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col class="d-flex justify-content-end">
                    <b-button variant="primary" :disabled="!canSynchronize || isSyncing" @click="syncGamesManually">
                        <template v-if="isSyncing">
                            <feather-icon icon="RefreshCcwIcon" size="18" class="cursor-pointer rotate" />
                            {{ $t("buttons.synchronizing") }}
                        </template>
                        <template v-else>
                            {{ $t("buttons.synchronize") }}
                            <feather-icon icon="RefreshCcwIcon" size="18" class="cursor-pointer ml-1" />
                        </template>
                    </b-button>
                </b-col>
            </b-row>

            <b-row v-if="syncResult" class="mt-2">
                <b-col>
                    <b-alert show variant="success" v-if="syncResult.success">
                        <div class="alert-body">
                            {{ syncResult.message }}
                        </div>
                    </b-alert>
                    <b-alert show variant="danger" v-else>
                        <div class="alert-body">
                            {{ syncResult.message }}
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</template>

<script>
import {
    BCard,
    BCol,
    BButton,
    BCardText,
    BRow,
    BOverlay,
    BSpinner,
    BAlert,
    BListGroup,
    BListGroupItem
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import productStoreModule from "@/store/products";
import gamesStoreModule from "@/store/games";

export default {
    components: {
        BCard,
        BButton,
        BCardText,
        BCol,
        BRow,
        BOverlay,
        BSpinner,
        BAlert,
        BListGroup,
        BListGroupItem,
        vSelect,
        ToastificationContent,
    },
    data() {
        return {
            selectedProduct: null,
            selectedBrand: null,
            selectedGames: [],
            productsOptions: [],
            brandsOptions: [],
            gamesOptions: [],
            loadingProducts: false,
            loadingBrands: false,
            loadingGames: false,
            isSyncing: false,
            syncResult: null,
        };
    },
    computed: {
        canSynchronize() {
            // Solo se requiere un producto seleccionado
            return this.selectedProduct !== null;
        },
        syncMode() {
            if (!this.selectedProduct) return null;
            if (!this.selectedBrand) return 'provider';
            if (this.selectedGames.length === 0) return 'brand';
            return 'games';
        },
        syncModeMessage() {
            if (!this.selectedProduct) return null;

            if (this.syncMode === 'provider') {
                return this.$t('labels.sync-mode-provider');
            } else if (this.syncMode === 'brand') {
                return this.$t('labels.sync-mode-brand');
            } else if (this.syncMode === 'games') {
                return this.$t('labels.sync-mode-games', { count: this.selectedGames.length });
            }

            return null;
        }
    },
    methods: {
        async loadProducts() {
            try {
                this.loadingProducts = true;
                await this.$store.dispatch("productsModule/getProducts");
                this.productsOptions = this.$store.getters["productsModule/productsActives"];
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando productos",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingProducts = false;
            }
        },
        async productSelected(productId) {
            if (productId) {
                this.selectedBrand = null;
                this.selectedGames = [];
                this.gamesOptions = [];
                await this.loadBrands(productId);
            } else {
                this.brandsOptions = [];
                this.selectedBrand = null;
                this.selectedGames = [];
                this.gamesOptions = [];
            }
        },
        async loadBrands(productId) {
            try {
                if (!productId) return;

                this.loadingBrands = true;
                const response = await this.$store.dispatch("brandModule/index", productId);
                this.brandsOptions = response.data.brands;
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando marcas",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingBrands = false;
            }
        },
        async brandSelected(brandId) {
            if (brandId) {
                this.selectedGames = [];
                await this.loadGames(this.selectedProduct, brandId);
            } else {
                this.gamesOptions = [];
                this.selectedGames = [];
            }
        },
        async loadGames(productId, brandId) {
            try {
                if (!productId || !brandId) return;

                this.loadingGames = true;

                const brandIdN = this.brandsOptions.find(brand => brand._id === brandId)?.brandId;
                const response = await this.$store.dispatch("gamesModule/getGames", {
                    producSelectId: productId,
                    params: {
                        page: 1,
                        perPage: 1000,
                        brandId: brandIdN,
                    },
                });
                this.gamesOptions = response.data.games;
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando juegos",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingGames = false;
            }
        },
        async syncGamesManually() {
            try {
                this.isSyncing = true;
                this.syncResult = null;

                const params = {
                    productId: this.selectedProduct,
                };

                if (this.selectedBrand) {
                    params.brandId = this.selectedBrand;
                }

                if (this.selectedGames.length > 0) {
                    params.gameIds = this.selectedGames;
                }

                const response = await this.$store.dispatch("bonus/syncGamesManually", params);

                if (response.data && response.data.syncedGames) {
                    const count = response.data.syncedGames;
                    let successMessage = '';

                    // Mensaje personalizado según el modo de sincronización
                    if (this.syncMode === 'provider') {
                        successMessage = `${count} ${count === 1 ? 'juego' : 'juegos'} sincronizados del proveedor correctamente.`;
                    } else if (this.syncMode === 'brand') {
                        successMessage = `${count} ${count === 1 ? 'juego' : 'juegos'} sincronizados de la marca correctamente.`;
                    } else {
                        successMessage = `${count} ${count === 1 ? 'juego específico' : 'juegos específicos'} sincronizados correctamente.`;
                    }

                    this.syncResult = {
                        success: true,
                        message: successMessage
                    };

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Sincronización exitosa",
                            icon: "CheckCircleIcon",
                            variant: "success",
                            text: successMessage,
                        },
                    });
                } else {
                    this.syncResult = {
                        success: false,
                        message: "No se pudieron sincronizar los juegos."
                    };
                }
            } catch (error) {
                console.error(error);
                this.syncResult = {
                    success: false,
                    message: error.response?.data?.message || "Error en la sincronización de juegos."
                };

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error en la sincronización",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: error.response?.data?.message || "Error en la sincronización de juegos.",
                    },
                });
            } finally {
                this.isSyncing = false;
            }
        },
    },
    mounted() {
        this.loadProducts();
    },
    created() {
        // Register module if not registered
        if (!store.hasModule("productsModule")) {
            store.registerModule("productsModule", productStoreModule);
        }

        if (!store.hasModule("gamesModule")) {
            store.registerModule("gamesModule", gamesStoreModule);
        }
    },
};
</script>

<style scoped>
.mt-3 {
    margin-top: 1rem;
}

.rotate {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>